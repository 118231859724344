import { FC } from "react";
import Script from "next/script";
import getConfig from "next/config";
import * as _Sentry from "@sentry/nextjs";

import { sentryScriptSource } from "@config/sentry";
import { environment } from "@config/general";
import { environments } from "@constants/environments";

export type WindowWithSentry = typeof window & {
  Sentry: typeof _Sentry & { onLoad: (cb: () => unknown) => unknown };
};

const SentryScript: FC = () => (
  <Script
    src={sentryScriptSource}
    crossOrigin="anonymous"
    onLoad={() => {
      const w = window as WindowWithSentry;
      w.Sentry.onLoad(() => {
        const { publicRuntimeConfig } = getConfig();
        const options = {
          environment: publicRuntimeConfig.SENTRY_ENVIRONMENT,
          release: publicRuntimeConfig.SENTRY_RELEASE,
          enabled:
            environment === environments.PRODUCTION &&
            publicRuntimeConfig.SENTRY_ENVIRONMENT !== "development",
        };
        w.Sentry.init(options);
      });
    }}
  />
);

export default SentryScript;
