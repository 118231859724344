type LocalStorageItems = {
  [itemName: string]: string;
};

export function getAllLocalStorageItems(): LocalStorageItems {
  if (typeof localStorage === "undefined") {
    return {};
  }

  return [...Array(localStorage.length).keys()].reduce((items, index) => {
    const key = localStorage.key(index);

    if (!key) {
      return items;
    }

    return {
      ...items,
      [key]: getLocalStorageItem(key),
    };
  }, {} as LocalStorageItems);
}

export function getLocalStorageItem(itemName: string): string {
  if (typeof localStorage === "undefined") {
    return "";
  }

  return localStorage.getItem(itemName) ?? "";
}

export function setLocalStorageItem(itemName: string, itemValue: string): void {
  if (typeof localStorage === "undefined") {
    return;
  }

  localStorage.setItem(itemName, itemValue);
}

export function removeLocalStorageItem(itemName: string): void {
  if (typeof localStorage === "undefined") {
    return;
  }

  localStorage.removeItem(itemName);
}

export function clearLocalStorage(): void {
  if (typeof localStorage === "undefined") {
    return;
  }

  localStorage.clear();
}
