export function calculatePrice(price: number, discount: number): number {
  return (1 - discount / 100) * price;
}

export function formatPrice(price: number, leaveDecimals?: boolean): string {
  const format = new Intl.NumberFormat("sr-SR", {
    style: "currency",
    currency: "EUR",
    // maximumFractionDigits: 0,
  }).format(price);

  return leaveDecimals ? format : format.replace(/,[0-9][0-9]/, "");
}
