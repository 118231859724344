import { Dispatch } from "react";

export interface State {
  items: number[];
}

export enum ActionType {
  INIT_WISHLIST,
  ADD_ITEM,
  REMOVE_ITEM,
  CLEAR_WISHLIST,
}

export type ChangeWishlistActionType =
  | ActionType.ADD_ITEM
  | ActionType.REMOVE_ITEM;

export type Action =
  | { type: ActionType.INIT_WISHLIST; value: number[] }
  | { type: ChangeWishlistActionType; value: number }
  | { type: ActionType.CLEAR_WISHLIST };

export interface ContextType {
  wishlist: State;
  dispatch: Dispatch<Action>;
}
