import { Primitive } from "type-fest";

export type WindowWithFacebookPixel = typeof window & {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  fbq(...args: any[]): void;
};

export function pageView(): void {
  (window as WindowWithFacebookPixel).fbq("track", "PageView");
}

export function event(
  name: string,
  options: Record<string, Primitive> = {}
): void {
  (window as WindowWithFacebookPixel).fbq("track", name, options);
}
