import { Dispatch } from "react";
import { Product } from "@prisma/client";

export interface Item {
  product: Product;
  count: number;
}

export interface State {
  items: Item[];
}

export enum ActionType {
  INIT_CART,
  ADD_TO_CART,
  INCREASE_COUNT,
  DECREASE_COUNT,
  SET_COUNT,
  REMOVE_FROM_CART,
  CLEAR_CART,
}

export type ProductSetActionType =
  | ActionType.ADD_TO_CART
  | ActionType.SET_COUNT;

export type ProductMutationActionType =
  | ActionType.INCREASE_COUNT
  | ActionType.DECREASE_COUNT
  | ActionType.REMOVE_FROM_CART;

export type Action =
  | { type: ProductSetActionType; product: Product; count: number }
  | { type: ProductMutationActionType; product: Product }
  | { type: ActionType.CLEAR_CART }
  | { type: ActionType.INIT_CART; items: Item[] };

export interface ContextType {
  cart: State;
  dispatch: Dispatch<Action>;
}
