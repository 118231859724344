import { Action, ActionType, State } from "@interfaces/wishlist";

export const WISHLIST_KEY = "WISHLIST" as const;

export function init(wishlist: Partial<State>): State {
  return { items: wishlist.items || [] };
}

export function storeToLocalStorage(wishlist: number[]): void {
  localStorage.setItem(WISHLIST_KEY, JSON.stringify(wishlist));
}

export function readFromLocalStorage(): number[] {
  const wishlist = localStorage.getItem(WISHLIST_KEY);

  if (!wishlist) {
    return [];
  }

  const array = JSON.parse(wishlist);

  if (!Array.isArray(array)) {
    return [];
  }

  return array;
}

export function reducer(wishlist: State, action: Action): State {
  let newWishlist: { items: number[] };

  switch (action.type) {
    case ActionType.INIT_WISHLIST:
      newWishlist = { items: action.value };
      break;
    case ActionType.CLEAR_WISHLIST:
      newWishlist = { items: [] };
      break;
    case ActionType.ADD_ITEM:
      if (wishlist.items.includes(action.value)) {
        newWishlist = wishlist;
      } else {
        newWishlist = { items: [...wishlist.items, action.value] };
      }
      break;
    case ActionType.REMOVE_ITEM:
      if (wishlist.items.includes(action.value)) {
        newWishlist = {
          items: wishlist.items.filter((id) => id !== action.value),
        };
      } else {
        newWishlist = wishlist;
      }
      break;
    default:
      newWishlist = wishlist;
      break;
  }

  if (
    wishlist.items.length !== newWishlist.items.length ||
    !newWishlist.items.every((newId) =>
      wishlist.items.some((id) => id === newId)
    )
  ) {
    storeToLocalStorage(newWishlist.items);
  }

  return newWishlist;
}
