import "tailwindcss/tailwind.css";
import "../styles/index.css";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../styles/slick-overrides.css";

import { AppProps } from "next/app";
import { FC, useEffect } from "react";
import { Provider } from "next-auth/client";
import { Session } from "next-auth";
import { appWithTranslation } from "next-i18next";
import Script from "next/script";
import { useRouter } from "next/router";

import CartContextProvider from "@context/cart";
import StaticContextProvider from "@context/static";
import { ErrorProps, StaticProps } from "@interfaces/page";
import WishlistContextProvider from "@context/wishlist";
import SentryScript from "@components/scripts/sentry";
import { pageView } from "@lib/facebook-pixel";

interface PageProps extends StaticProps {
  session?: Session;
}

interface Props extends AppProps<ErrorProps>, ErrorProps {
  pageProps: PageProps;
}

const App: FC<Props> = ({ Component, pageProps, err }) => {
  const { staticData, session, ...rest } = pageProps;
  const { events } = useRouter();

  useEffect(() => {
    pageView();

    const handleRouteChange = () => {
      pageView();
    };

    events.on("routeChangeComplete", handleRouteChange);

    return () => {
      events.off("routeChangeComplete", handleRouteChange);
    };
  }, [events]);

  return (
    <>
      <Provider {...{ session }}>
        <StaticContextProvider {...staticData}>
          <WishlistContextProvider>
            <CartContextProvider>
              <Script
                dangerouslySetInnerHTML={{
                  __html: `
                    !function(f,b,e,v,n,t,s){if(f.fbq)return;n=f.fbq=function(){n.callMethod?
                    n.callMethod.apply(n,arguments):n.queue.push(arguments)};
                    if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
                    n.queue=[];t=b.createElement(e);t.async=!0;
                    t.src=v;s=b.getElementsByTagName(e)[0];
                    s.parentNode.insertBefore(t,s)}(window, document,'script',
                    'https://connect.facebook.net/en_US/fbevents.js');
                    fbq('init', '244398844533183');
                    fbq('track', 'PageView');
                  `,
                }}
              />
              {/* <!-- Global site tag (gtag.js) - Google Analytics --> */}
              <script
                async
                src="https://www.googletagmanager.com/gtag/js?id=UA-220962982-1"
              ></script>
              <Script
                dangerouslySetInnerHTML={{
                  __html: `
                    window.dataLayer = window.dataLayer || [];
                    function gtag(){dataLayer.push(arguments);}
                    gtag('js', new Date());

                    gtag('config', 'UA-220962982-1');
                  `,
                }}
              />
              <Component {...{ ...rest, err }} />
            </CartContextProvider>
          </WishlistContextProvider>
        </StaticContextProvider>
      </Provider>
      <SentryScript />
    </>
  );
};

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export default appWithTranslation(App);
