import {
  createContext,
  FC,
  useContext,
  useEffect,
  useReducer,
  useRef,
  useState,
} from "react";
import { useSession } from "next-auth/client";

import { ActionType, ContextType, State } from "@interfaces/wishlist";
import {
  init,
  readFromLocalStorage,
  reducer,
  WISHLIST_KEY,
} from "@lib/wishlist";

const WishlistContext = createContext<ContextType>({} as ContextType);

export const useWishlist = (): ContextType => useContext(WishlistContext);

export const useWishlistItem = (
  id: number
): { isInWishlist: boolean; add(): void; remove(): void; toggle(): void } => {
  const {
    wishlist: { items },
    dispatch,
  } = useWishlist();

  const isInWishlist = items && items.includes(id);

  function add(): void {
    dispatch({ type: ActionType.ADD_ITEM, value: id });
  }

  function remove(): void {
    dispatch({ type: ActionType.REMOVE_ITEM, value: id });
  }

  function toggle(): void {
    if (isInWishlist) {
      remove();
    } else {
      add();
    }
  }

  return {
    isInWishlist,
    add,
    remove,
    toggle,
  };
};

const WishlistContextProvider: FC = ({ children }) => {
  const [session] = useSession();
  const user = session?.user ?? null;
  const userRef = useRef(user);

  const [wishlist, dispatch] = useReducer<typeof reducer, State>(
    reducer,
    { items: [] },
    init
  );

  const [userDataInitialized, setUserDataInitialized] = useState(false);

  useEffect(() => {
    const items = readFromLocalStorage();
    dispatch({ type: ActionType.INIT_WISHLIST, value: items });

    const syncStorage = ({ key }: StorageEvent) => {
      if (key === WISHLIST_KEY) {
        const items = readFromLocalStorage();
        dispatch({
          type: ActionType.INIT_WISHLIST,
          value: items,
        });
      }
    };

    window.addEventListener("storage", syncStorage);

    return () => {
      window.removeEventListener("storage", syncStorage);
    };
  }, []);

  useEffect(() => {
    if (!user && user !== userRef.current) {
      dispatch({ type: ActionType.CLEAR_WISHLIST });
      setUserDataInitialized(false);
    }
    userRef.current = user;
  }, [user]);

  useEffect(() => {
    if (user) {
      const { items } = wishlist;

      if (userDataInitialized) {
        const abortController = new AbortController();
        const signal = abortController.signal;

        fetch("/api/wishlist", {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ wishlist: items }),
          signal,
        });

        return () => abortController.abort();
      } else {
        fetch("/api/wishlist").then(async (response) => {
          if (response.ok) {
            const { wishlist } = await response.json();
            if (wishlist.length > 0) {
              dispatch({
                type: ActionType.INIT_WISHLIST,
                value: [
                  ...items,
                  ...wishlist.filter((id: number) => !items.includes(id)),
                ],
              });
            }
            setUserDataInitialized(true);
          }
        });
      }
    }
  }, [wishlist, user, userDataInitialized]);

  return (
    <WishlistContext.Provider value={{ wishlist, dispatch }}>
      {children}
    </WishlistContext.Provider>
  );
};

export default WishlistContextProvider;
