import { createContext, FC, useContext } from "react";

import { StaticData } from "@interfaces/page";

type StaticContextType = StaticData;
// TODO: maybe include title and meta description as well
// Is this actually  layout context or should it include any static properties
// that can be used within a page i.e. carousel photos on home page, supplier
// info for categories not listed on the website etc.

const CartContext = createContext<StaticContextType>({} as StaticContextType);

export const useStaticData = (): StaticContextType => useContext(CartContext);

const StaticContextProvider: FC<StaticContextType> = ({
  children,
  newsletter,
  categories,
  socialLinks,
  contentPages,
}) => {
  return (
    <CartContext.Provider
      value={{ newsletter, socialLinks, categories, contentPages }}
    >
      {children}
    </CartContext.Provider>
  );
};

export default StaticContextProvider;
