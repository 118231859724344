import { Action, ActionType, State } from "@interfaces/cart";
import { getLocalStorageItem, setLocalStorageItem } from "@utils/local-storage";
import { calculatePrice } from "@utils/price";

export const CART_KEY = "CART" as const;
const CART_AGE = 1_000 * 60 * 60 * 24 * 3; // 3 days

export function readFromLocalStorage(): State {
  const emptyCart = { items: [] };

  const stringValue = getLocalStorageItem(CART_KEY);

  if (!stringValue) {
    return emptyCart;
  }

  const { cart, expires } = JSON.parse(stringValue) as {
    cart: State;
    expires: number;
  };

  if (expires < Date.now()) {
    return emptyCart;
  }

  return cart || emptyCart;
}

function storeToLocalStorage(cart: State): void {
  setLocalStorageItem(
    CART_KEY,
    JSON.stringify({ cart, expires: Date.now() + CART_AGE })
  );
}

export function getCartTotal(cart: State): number {
  return cart.items.reduce(
    (total, { count, product: { price, discount } }) =>
      total + count * calculatePrice(price, discount),
    0
  );
}

export function init(cart: Partial<State>): State {
  if (cart.items) {
    return {
      items: cart.items,
    };
  }

  return { items: [] };
}

export function getTotalItems(cart: State): number {
  return cart.items.reduce((total, { count }) => total + count, 0);
}

export function reducer(cart: State, action: Action): State {
  let index: number;
  let newCart: State = cart;

  switch (action.type) {
    case ActionType.INIT_CART:
      newCart = { items: action.items };
      // Check if carts are different to prevent infinite loops dooring storage syncing
      if (
        newCart.items.length !== cart.items.length ||
        !newCart.items.every(({ product: { id } }) =>
          cart.items.some(({ product }) => product.id === id)
        )
      ) {
        storeToLocalStorage(newCart);
      }

      return newCart;
    case ActionType.ADD_TO_CART:
      index = cart.items.findIndex(
        ({ product: { id } }) => id === action.product.id
      );

      if (index !== -1) {
        newCart = {
          items: cart.items.map((item, idx) =>
            idx === index ? { ...item, count: item.count + action.count } : item
          ),
        };
      } else {
        newCart = { items: [...cart.items, { ...action }] };
      }

      break;
    case ActionType.INCREASE_COUNT:
      index = cart.items.findIndex(
        ({ product: { id } }) => id === action.product.id
      );

      if (index !== -1) {
        newCart = {
          items: cart.items.map((item, idx) =>
            idx === index ? { ...item, count: item.count + 1 } : item
          ),
        };
      }

      break;
    case ActionType.DECREASE_COUNT:
      index = cart.items.findIndex(
        ({ product: { id } }) => id === action.product.id
      );

      if (index !== -1) {
        newCart = {
          items: cart.items.map((item, idx) =>
            idx === index ? { ...item, count: item.count - 1 } : item
          ),
        };
      }

      break;
    case ActionType.SET_COUNT:
      index = cart.items.findIndex(
        ({ product: { id } }) => id === action.product.id
      );

      if (index !== -1) {
        newCart = {
          items: cart.items.map((item, idx) =>
            idx === index ? { ...item, count: action.count } : item
          ),
        };
      } else {
        newCart = { items: [...cart.items, { ...action }] };
      }

      break;
    case ActionType.REMOVE_FROM_CART:
      index = cart.items.findIndex(
        ({ product: { id } }) => id === action.product.id
      );

      if (index !== -1) {
        newCart = {
          items: cart.items.filter((_, idx) => idx !== index),
        };
      }

      break;
    case ActionType.CLEAR_CART:
      newCart = { items: [] };

      break;
    default:
      break;
  }

  storeToLocalStorage(newCart);
  return newCart;
}
