/* eslint-disable node/no-process-env */

export const environment = process.env.NODE_ENV || "development";

export const emailSender =
  process.env.EMAIL_SENDER || "Renya Home <no-reply@renyahome.me>";

export const inquiryEmail =
  process.env.INQUIRY_EMAIL_RECIPIENT || "info@renyahome.me";

export const mediaRoot = process.env.NEXT_PUBLIC_MEDIA_ROOT || "/";

export const domain = process.env.NEXTAUTH_URL || "";

export const googleMapsAPIKey =
  process.env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY || "";
