import {
  createContext,
  FC,
  useContext,
  useEffect,
  useReducer,
  useRef,
  useState,
} from "react";
import { useSession } from "next-auth/client";

import { State, ContextType, ActionType } from "@interfaces/cart";
import { CART_KEY, init, readFromLocalStorage, reducer } from "@lib/cart";

const CartContext = createContext<ContextType>({} as ContextType);

export const useCart = (): ContextType => useContext(CartContext);

const CartContextProvider: FC = ({ children }) => {
  const [session] = useSession();
  const user = session?.user ?? null;
  const userRef = useRef(user);

  const [cart, dispatch] = useReducer<typeof reducer, State>(
    reducer,
    { items: [] },
    init
  );

  const [userDataInitialized, setUserDataInitialized] = useState(false);

  useEffect(() => {
    const { items } = readFromLocalStorage();
    dispatch({ type: ActionType.INIT_CART, items });

    const syncStorage = ({ key }: StorageEvent) => {
      if (key === CART_KEY) {
        const { items } = readFromLocalStorage();
        dispatch({
          type: ActionType.INIT_CART,
          items,
        });
      }
    };

    window.addEventListener("storage", syncStorage);

    return () => {
      window.removeEventListener("storage", syncStorage);
    };
  }, []);

  useEffect(() => {
    if (!user && user !== userRef.current) {
      dispatch({ type: ActionType.CLEAR_CART });
      setUserDataInitialized(false);
    }
    userRef.current = user;
  }, [user]);

  useEffect(() => {
    if (user) {
      const { items } = cart;

      if (userDataInitialized) {
        // No need for debouncing as items aren't going to be added too quickly
        const abortController = new AbortController();
        const signal = abortController.signal;

        fetch("/api/cart", {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            cart: items.map(({ count, product: { id: productId } }) => ({
              count,
              productId,
            })),
          }),
          signal,
        });

        return () => abortController.abort();
      } else {
        if (items.length === 0) {
          fetch("/api/cart").then(async (response) => {
            if (response.ok) {
              const items = await response.json();
              if (items.length > 0) {
                dispatch({ type: ActionType.INIT_CART, items });
              }
            }
            setUserDataInitialized(true);
          });
        } else {
          setUserDataInitialized(true);
        }
      }
    }
  }, [cart, user, userDataInitialized]);

  return (
    <CartContext.Provider value={{ cart, dispatch }}>
      {children}
    </CartContext.Provider>
  );
};

export default CartContextProvider;
